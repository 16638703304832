<template>
  <div class="images">
    <OfficialHead ref="header" :homePageSetting="homePageSetting" />
    <!-- banner start -->
    <div class="banner">
      <div class="banner">
        <img :src="bannerImgUrl" class="banner-img">
      </div>
    </div>
    <!-- banner end -->
    <!-- content start -->
    <div class="content" v-loading="queryLoading">
      <div class="container">
        <div class="content-list">
          <div class="row" v-if="!dataList.length">
            <el-empty :image-size="320"></el-empty>
          </div>
          <div class="row" v-else>
            <a :href="getImgDetailUrl(item)" target="_blank" class="col-12 col-lg-4 col-sm-6 list-item" v-for="item of dataList" :key="item.id">
              <el-image :src="item.picture" fit="cover" lazy></el-image>
              <div class="item-content">
                <div class="content-title">{{ item.title }}</div>
                <div class="content-date">{{ item.publishTimeStr }}</div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- content end -->
    <!-- pagination start -->
    <div class="pagination">
      <div class="container">
        <div class="row">
          <div class="col-12 d-none d-lg-block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="query.page"
              :page-size="query.pageSize"
              layout="prev, pager, next, jumper, slot"
              prev-text="上一页"
              next-text="下一页"
              :total="total"
            >
              <template slot>
                <div class="pagination-btn">GO</div>
              </template>
            </el-pagination>
          </div>
          <div class="col-12 d-lg-none">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="query.page"
              :page-size="query.pageSize"
              layout="prev, pager, next"
              :total="total"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- pagination end -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import { getHomePageSetting, setSeo } from '../api/defaultDataModel'
import OfficialHead from './OfficialHead'
import OfficialFoot from './OfficialFoot'

export default {
  name: 'ImageList',
  components: {
    OfficialHead,
    OfficialFoot
  },
  data () {
    return {
      homePageSetting: getHomePageSetting(),
      currentPage: 5,
      //总记录数
      total: 0,
      //分页数据
      dataList: [],
      queryLoading: false,
      query: {
        page: 1,
        pageSize: 12,
        articleListId: null
      },
      bannerImgUrl: null
    }
  },
  created () {
    //当前关于数据
    if (this.$route.query.id && /^\d{15,}$/.test(this.$route.query.id)) {
      this.getSetting()
      this.queryListInfo()
    } else {
      // this.$router.push({ path: '/' })
    }
  },
  mounted() {
    window.removeEventListener('scroll', this.$refs.header.handleScroll)
    this.$refs.header.navigationHeaderClass = ['header', 'header-fixed']
  },
  methods: {
    async getSetting () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + '/h5/homePageSetting/getSetting')
      const { data, code } = res.data
      if (code === 8000) {
        this.homePageSetting = data
        setSeo(
          this.homePageSetting.seoTitle,
          this.homePageSetting.seoDescription,
          this.homePageSetting.seoKeyword
        )
      }
    },
    async queryListInfo () {
      const res = await this.$http.get(this.TzConfig.BASE_URL + "/h5/articleList/getListInfo", { params: { id: this.$route.query.id } })
      try {
        if (8000 === res.data.code) {
          const articleListInfo = res.data.data
          const { bannerImgUrl } = res.data.data
          this.bannerImgUrl = bannerImgUrl 
          if (2 !== articleListInfo.listType && 1 !== articleListInfo.listType) {
            this.$router.push({ path: '/' })
          }
          //检查布局类型
          const checkLayoutType = (layoutType, id) => {
            //文字列表
            if (1 === layoutType) {
              this.$router.push({ path: '/plainTextNews?id=' + id })
              return false
            }
            //小图列表
            if (2 === layoutType) {
              this.$router.push({ path: '/smallPictureNews?id=' + id })
              return false
            }
            //大图列表
            if (3 === layoutType) {
              this.$router.push({ path: '/bigPictureNews?id=' + id })
              return false
            }
            return true
          }
          //当前查询的是双层文章列表
          if (2 === articleListInfo.listType) {
            //当前查询的是父级分类
            if (this.$route.query.id === articleListInfo.id) {
              //重新查第一个子分类
              if (!checkLayoutType(articleListInfo.children[0].layoutType, articleListInfo.children[0].id)) {
                return
              }
              this.$router.push({ path: '/smallPictureNews?id=' + articleListInfo.children[0].id })
              window.location.reload()
              return
            }
            //实际查询的子分类
            const articleList = articleListInfo.children.filter((itm) => itm.id === this.$route.query.id)[0]
            if (!checkLayoutType(articleList.layoutType, articleList.id)) {
              return
            }
            this.query.articleListId = articleList.id
          } else if (1 === articleListInfo.listType) {
            //单层文章列表
            if (!checkLayoutType(articleListInfo.layoutType, articleListInfo.id)) {
              return
            }
            this.query.articleListId = articleListInfo.id
          }
          //查询分页数据
          if (!this.query.articleListId) {
            this.$router.push({ path: '/' })
            return
          }
          this.queryList()
        } else {
          this.$router.push({ path: '/' })
          return
        }
      } catch (err) {
        this.$router.push({ path: '/' })
      }
    },
    async queryList() {
      this.queryLoading = true
      //分页获取数据
      const res = await this.$http.get(this.TzConfig.BASE_URL + "/h5/article/list", { params: this.query })
      if (8000 === res.data.code) {
        const { totalSize, list } = res.data.data
        this.total = totalSize
        this.dataList = list
      }
      this.queryLoading = false
    },
    getImgDetailUrl(data) {
      if (data) {
        if (1 === data.articleType) {
          return `/importantDetail?id=${data.id}` 
        }
        return `/detail?id=${data.id}`
      }
      return '/'
    },
    handleCurrentChange () {
      this.queryList()
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../assets/css/font.css';
  .images {
    width: 100%;
    min-height: 100vh;
    padding-top: 88px;
    font-family: pingFangSC-Medium !important;
    color: #222222;
    background: #f4f6f8;
    .banner {
      width: 100%;
      height: 360px;
      .banner-img {
        width: 100%;
        height: 100%;
      }
    }
    .content {
      margin-top: 30px;
      .content-list {
        .el-empty {
          width: 100%;
          /deep/ .el-empty__description {
            p {
              font-size: 24px;
            }
          }
        }
        .list-item {
          margin-top: 30px;
          &:nth-of-type(1),
          &:nth-of-type(2),
          &:nth-of-type(3) {
            margin-top: 0;
          }
          .el-image {
            width: 100%;
            height: 300px;
          }
          .item-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 120px;
            box-sizing: border-box;
            padding: 0 20px;
            margin-top: -5px;
            background: #ffffff;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
            .content-title {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              line-height: 32px;
              font-size: 20px;
              font-weight: 700;
            }
            .content-date {
              margin-top: 10px;
              font-size: 14px;
              color: #999999;
            }
          }
        }
      }
    }
    .pagination {
      margin-top: 30px;
      .el-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        /deep/ .btn-prev,
        /deep/ .btn-next {
          width: 84px;
          height: 42px;
          border: 1px solid #eeeeee;
          border-radius: 25px;
          padding: 0;
          background: inherit;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            font-family: pingFangSC-Medium !important;
            font-size: 16px;
            color: #606266;
          }
        }
        /deep/ .el-pager {
          li {
            width: 42px;
            height: 42px;
            line-height: 42px;
            border: 1px solid #eeeeee;
            border-radius: 50%;
            margin: 0 8px;
            font-size: 16px;
            background: inherit;
            &:hover {
              color: #1f5ed1;
            }
          }
          .active {
            color: #ffffff;
            background: #1f5ed1;
            &:hover {
              color: #ffffff;
            }
          }
        }
        /deep/ .el-pagination__jump {
          height: inherit;
          line-height: inherit;
          margin-left: 16px;
          .el-pagination__editor {
             width: 84px;
            height: 42px;
            line-height: 42px;
            margin: 0 8px;
            .el-input__inner {
              height: 100%;
              border-radius: 25px;
            }
          }
        }
        /deep/ .pagination-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 42px;
          height: 42px;
          line-height: 42px;
          border: 1px solid #1f5ed1;
          border-radius: 50%;
          margin-left: 8px;
          color: #1f5ed1;
          cursor: pointer;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .images {
      .content {
        .list-item {
          &:nth-of-type(3) {
            margin-top: 30px !important;
          }
        }
      }
      .pagination {
        .el-pagination {
          padding: 0;
          /deep/ .btn-prev,
          /deep/ .btn-next {
            padding: 0;
            border: none;
            .el-icon {
              font-size: 14px;
            }
          }
          /deep/ .el-pager {
            li {
              margin: 0 4px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .images {
      padding-top: 64px;
      .content {
        margin-top: 30px;
        .content-list {
          .list-item {
            margin-top: 30px;
            &:nth-of-type(1) {
              margin-top: 0;
            }
            &:nth-of-type(2) {
              margin-top: 30px;
            }
            &:hover {
              color: #0b46b1;
            }
            .item-content {
              .content-title {
                font-size: 16px;
              }
              .content-date {
                font-size: 12px;
              }
            }
          }
        }
      }
      .pagination {
        .el-pagination {
          /deep/ .btn-prev,
          /deep/ .btn-next {
            width: inherit;
            height: inherit;
            padding: 0;
            border: none;
          }
          /deep/ .el-pager {
            li {
              width: 33px;
              min-width: inherit;
              height: 33px;
              line-height: 33px;
              margin: 0 2px;
              font-size: 12px;
            }
          }
        }
      }
      .footer {
        margin-top: 30px;
      }
    }
  }
</style>
